@import "~desktop";

@media @desktop {
  .ContactDetailsBar {
    display: flex;
    position: fixed;
    top: 30%;
    right: 16px;
    padding: 22px 16px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    z-index: 100;
    background-color: transparent;
    border-radius: 12px;

    &__Content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__Body {
        max-width: 67px;
        font-size: 12px;
        line-height: 16px;
        color: #202325;
        margin-top: 9px;
        margin-bottom: 25px;
      }

      &__Img {
        width: 65px;
        height: 65px;
        margin-bottom: 18px;
      }

      &__SidePopup {
        position: absolute;
        z-index: 100;
        right: 100px;
        top: 195px;
        padding: 7px 8px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        background: white;
        border-radius: 6px;

        &__Inner {
          color: #ee4d2d;
          display: flex;
          font-size: 20px;
          line-height: 23px;
          align-items: center;
          padding: 6px 13px;
          border: 1px solid #ee4d2d;
          border-radius: 6px;

          &__Img {
              margin-right: 6px;
          }
        }
      }

      &__SidePopup:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -9px;
        border-width: 9px;
        border-style: solid;
        border-color: transparent transparent transparent #fff;
      }
    }
  }
}

.ContactDetailsBarMobile {
  display: flex;
  flex-direction: column;
  &__Wrapper {
    position: fixed;
    top: 52%;
    right: 14px;
    z-index: 100;
    padding: 10px 10px;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    border-radius: 12px;

    &__Content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__Body {
        max-width: 40px;
        font-size: 10px;
        line-height: 12px;
        color: #202325;
        margin-top: 6px;
        margin-bottom: 8px;
      }

      &__Img {
        width: 35px;
        height: 35px;
        margin-bottom: 12px;
      }
    }
  }

  &__Toggle {
    position: fixed;
    top: 80%;
    right: 14px;
    z-index: 100;
    margin-top: 16px;
    background-color: white;
    padding: 16px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
  }
}

@primary-color: rgb(238, 77, 45);