// ===== Shopee 2021 =====
@font-face {
  font-family: "Shopee_font Bold";
  src: url("./ShopeeFont/Shopee2021-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Shopee_font Medium";
  src: url("./ShopeeFont/Shopee2021-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Shopee_font";
  src: url("./ShopeeFont/Shopee2021-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// ===== Opensans =====
@font-face {
  font-family: "Open Sans SemiBold";
  src: url("./OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// ===== Roboto =====
@font-face {
  font-family: "Roboto Bold";
  src: url("./Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Medium";
  src: url("./Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Regular";
  src: url("./Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@primary-color: rgb(238, 77, 45);